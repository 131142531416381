import { FC, memo } from "react";
import Modal from "components/Modal";
import { Button } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void> | void;
};

const ResetLoginPrompt: FC<Props> = ({ show, onClose, onSubmit }) => {
  return (
    <Modal
      title=""
      show={show}
      onClose={onClose}
      customFooter={
        <div className="grid grid-flow-col justify-center gap-x-3">
          <Button.Cancel onButtonClick={onClose} />
          <Button color="primary" onButtonClick={() => onSubmit()}>
            Reset Login
          </Button>
        </div>
      }
    >
      <div className="grid justify-center justify-items-center gap-y-10">
        <FontAwesomeIcon
          icon={icon({ name: "circle-exclamation" })}
          size="3x"
          color="red"
        />
        <p className="font-bold text-2xl">Yakin akan melakukan reset login siswa?</p>
        <p className="text-gray-400">
          Semua sesi login yang aktif pada siswa akan otomatis ter-logout.
        </p>
      </div>
    </Modal>
  );
};

export default memo(ResetLoginPrompt);
