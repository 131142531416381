import { Label } from "components";
import { memo, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { currencyConverter, priceByDiscount } from "utilities";
import ListMeetings from "./fragment/ListMeetings";
import QuestionComposition from "./fragment/MeetingsComposition";
import { services } from "services";

const Summary = () => {
  const { getValues } = useFormContext<LiveclassPayload>();
  const title = getValues("title");
  const description = getValues("description");
  const thumbnail = getValues("thumbnail_path");
  const price = getValues("price");
  const discount = getValues("discount");
  const isFlatDisc = getValues("is_flat_disc");
  const questions = getValues("question_package");
  const categories = getValues("categories");
  const startDate = getValues("registration_period_start");
  const endDate = getValues("registration_period_end");
  const strata = getValues("grade_id");
  const mapel = getValues("subject_id");
  const meetings = getValues("meetings");


  const finalPrice = useMemo(() => {
    if (isFlatDisc) {
      return currencyConverter(price - discount);
    } else {
      return priceByDiscount(price, discount);
    }
  }, [isFlatDisc, price, discount]);

  const [grades, setGrades] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);
  
  async function GetGrade() {
    services
      .get("dropdown/grade")
      .then((resp) => {
        const data = resp?.data?.data;

        setGrades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetSubject() {
    services
      .get("dropdown/subject")
      .then((resp) => {
        const data = resp?.data?.data;
        setSubjects(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    GetGrade();
    GetSubject();
  }, []);

  function getGradeById(id: number) {
    const found = grades.find(item => item.id === id);
    return found ? found.name : "Name not found";
  }

  function getSubjectById(id: number) {
    const found = subjects.find(item => item.id === id);
    return found ? found.name : "Name not found";
  }
  
  return (
    <section className="grid gap-y-5">
      <div className="flex gap-x-8">
        <div className="flex-[55%] bg-white rounded-xl">
          <p className="font-bold px-5 py-4 border-b">Informasi program</p>
          <div className="p-5 grid gap-y-4">
            <div>
              <p className="text-gray-500 font-medium">Judul program</p>
              <p>{title}</p>
            </div>
            <div>
              <p className="text-gray-500 font-medium">Deskripsi</p>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div>
              <Label>Thumbnail</Label>
              <img
                src={thumbnail}
                alt="program"
                className="w-72 rounded aspect-[1.6] bg-gray-50"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <div className="bg-white rounded-xl">
            <p className="font-bold px-5 py-4 border-b">Harga</p>
            <div className="grid gap-y-3 p-5">
              <div className="flex items-center gap-x-1.5">
                <p className="w-36 text-gray-500">Harga program</p>
                <p>{currencyConverter(price)}</p>
              </div>
              <div className="flex items-center gap-x-1.5">
                <p className="w-36 text-gray-500">Diskon</p>
                <p className="text-red-500">
                  {isFlatDisc
                    ? `${currencyConverter(discount)}`
                    : `${discount}%`}
                </p>
              </div>
              <div className="flex items-center gap-x-1.5">
                <p className="w-36 text-gray-500">Harga final</p>
                <p className="font-bold">{finalPrice}</p>
              </div>
              <p className="text-xs text-gray-500">
                * Harga final akan ditampilkan pada mobile dan web app
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl">
            <p className="font-bold px-5 py-4 border-b">Konfigurasi</p>
            <div className="grid gap-y-3 p-5">
              <div className="flex items-center gap-x-1.5">
                <p className="w-40 text-gray-500">Jenis Live Class</p>
                <p>{categories !== true ? "Grup" : "Privat"}</p>
              </div>
              <div className="flex items-center gap-x-1.5">
                <p className="w-40 text-gray-500">Periode pendaftaran</p>
                <p>{startDate} - {endDate}</p>
              </div>
              <div className="flex items-center gap-x-1.5">
                <p className="w-40 text-gray-500">Strata</p>
                <p>{getGradeById(strata !== null ? Number(strata) : 1)}</p>
              </div>
              <div className="flex items-center gap-x-1.5">
                <p className="w-40 text-gray-500">Mata pelajaran</p>
                <p>{getSubjectById(mapel !== null ? Number(mapel) : 1)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-xl">
        <p className="font-bold px-5 py-4 border-b">Daftar Pertemuan</p>
        <ListMeetings data={meetings} />
      </div>
    </section>
  );
};

export default memo(Summary);
